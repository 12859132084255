import Card from "components/card";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RecipesReport, sectionmapper } from "../../../Redux/Report/action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import axios from "axios";
import { apiurl } from "configs/config";
import toast from "react-hot-toast";

function VazhipaduWiseItemReport() {
  const Recipeslist = useSelector((state) => state.Report.Recipes);
  const sectionlist = useSelector((state) => state.Report.section);
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const dispatch = useDispatch();
  const [ItemData, setItemData] = useState();
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const formattedDate = new Date(selectedDate).toISOString();
  console.log("selectedSession  ", selectedSession);
  console.log("Recipeslist  ", Recipeslist);



// Convert string to Date object
const date = new Date(selectedDate);

// Set hours, minutes, and seconds to 0
date.setUTCHours(0);
date.setUTCMinutes(0);
date.setUTCSeconds(0);

// Format the date as required
const formattedDate = date.toISOString().slice(0, 10) + " 00:00:00.000";

console.log(formattedDate); // Output: 2024-11-01 00:00:00.000

  useEffect(() => {
    console.log("selectedSession?.value",selectedSession?.value);
    
    if(selectedSession?.value){
      console.log("if");
      
    dispatch(
      RecipesReport({
        TempleId: TempleID,
        Date: formattedDate,
        // Date: "2024-03-21 00:00:00.000",
        // Session: 26,
        Session: selectedSession?.value,
      })
    );

  }
  console.log("else");
  
  }, [ItemData,formattedDate,selectedSession]);
 

  useEffect(() => {
    // Fetch session options from backend
    console.log("TempleID",TempleID);
    axios
      .get(`${apiurl}/api/Session/GetSessionMapperData?TempleId=${TempleID}`)
      .then((response) => {
        console.log("response", response);
        if (response.data&& response.data.Result) {
          setSelectedSession({
            value:response.data.Result[0].Id,
             label:response.data.Result[0].Name});
             const items = response?.data?.Result?.map((session) => ({
              value: session.Id,
              label: session.Name,
            }))

            console.log("items",items);
            
           if(items){
            
            setItemData(items)
           } 
          // setItemData(
          //   response.data.Result.map((session) => ({
          //     value: session.Id,
          //     label: session.Name,
          //   }))
          // );
        }
        
      })
      .catch((error) => {
        console.error(error, "hj");
        toast.error("Error fetching session details");
      });
    // if(TempleID){
    //   dispatch(sectionmapper({
    //     TempleId:TempleID
    //   }))
    // }
   
  }, [TempleID]);




  const uniqueSevaNames = [...new Set(Recipeslist&&Recipeslist.map(item => item.SevaName))];
  const uniqueNames = [...new Set(Recipeslist&&Recipeslist.map(item => item.Name))];
  
  // Initialize a matrix to hold Quantity values for each combination of SevaName and Name
  const quantityMatrix = uniqueNames.map(name =>
    uniqueSevaNames.map(sevaName =>
      Recipeslist&&Recipeslist.find(item => item.Name === name && item.SevaName === sevaName)?.Quantity || 0
    )
  );
  // console.log("xAxisLabels",xAxisLabels);
  // console.log("yAxisLabels",yAxisLabels);
  // console.log("dataMatrix",tableData);

  return (
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="pb-5 text-xl font-bold text-navy-700 dark:text-white">
          Recipe Report
        </div>
        <div className="flex items-center">
          {/* Date Picker */}
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            className="mr-5"
          />
          {/* Dropdown */}
          <Select
            options={ItemData}
            placeholder="Select Session"
            value={selectedSession}
            onChange={(selectedOption) =>
              setSelectedSession(selectedOption ? selectedOption : null)
            }
            isSearchable={false}
          />
          {/* Export to Excel Button */}
        </div>
      </div>
      <div className="h-full md:h-[500px] md:overflow-y-auto ">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
  
        <div className="max-h-64 overflow-y-auto">
  <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
    <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" className="px-6 py-3"></th>
        {uniqueSevaNames.map((label, index) => (
          <th key={index} className="px-6 py-3">
            {label}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {uniqueNames.map((yLabel, yIndex) => (
        <tr key={yIndex} className={`border-b ${yIndex % 2 === 0 ? 'even:bg-gray-50 dark:bg-gray-800' : 'odd:bg-white'} ${yIndex % 2 === 0 ? 'even:bg-gray-800 dark:bg-gray-800' : 'odd:bg-gray-900'}`}>
          <td className="px-6 py-4 text-gray-900">{yLabel}</td>
          {quantityMatrix[yIndex].map((cellData, xIndex) => (
            <td key={xIndex} className="px-6 py-4 text-gray-900">{cellData}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
</div>

        </div>
      </div>
    </Card>
  );
}

export default VazhipaduWiseItemReport;
