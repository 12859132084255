import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { GetAllParty, GetParty } from "../../../Redux/Party/action";
import { Listitem } from "../../../Redux/Items/action";
import toast from "react-hot-toast";
import { FaEdit } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import { MdDeleteForever, MdEditNote } from "react-icons/md";
import Select from "react-select";
import PurchaseItemModal from "../Purchase/PurchaseItemModal";
import {
  PrintPurchase,
  PrintPurchaseFail,
  SaleInsert,
  SaleInsertFail,
} from "../../../Redux/Sale/action";
import { ResetIcon } from "@radix-ui/react-icons";
import ItemFetchModal from "./ItemFetchModal";
import PrintIssue from "views/Print/PrintIssue";
import ReactToPrint from "react-to-print";

function Sales() {
  const [ChoosedItems, setChoosedItems] = useState([]);
  const [ShowModal, setShowModal] = useState(false);
  const [Qty, setQty] = useState("");
  const [AdvanceAmount, setAdvanceAmount] = useState("");
  const [DiscountAmount, setDiscountAmount] = useState("");
  const [pending, setpending] = useState("");
  const [selectitem, setselectitem] = useState("");
  const [tablelist, setTablelist] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
  } = useForm({
    defaultValues: {
      DeliveryDate: format(new Date(), "yyyy-MM-dd"),
      AdvanceAmount: "",
      paymentType:"2"
    },
    mode: "all",
  });

  const [fetch, setfetch] = useState(false);
  const handleFetchSuccess = (data) => {
    // setFetchedData(data);
    if (data.length > 0) {
      setfetch(true);
      // if(fetch){
      
      const Deatils = data.map(item => ({
        ...item, 
        Id: item.ItemId
      }));
      
      console.log("ewffew",Deatils);
      setTablelist(Deatils);
      console.log("data",data);
      
      // }
    }
  };


  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const UserId = JSON.parse(localStorage.getItem("Sopanam_UserId"));
  const PartyData = useSelector((state) => state.Party.GetAllPartyData);
  const ItemData = useSelector((state) => state.Items.ListItemData);
  const SaleData = useSelector((state) => state.Sale.SaleData);

  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetAllParty(TempleID));
    Dispatch(
      Listitem({
        TempleId: TempleID,
      })
    );
  }, []);
  const TotalAmount = tablelist?.reduce(
    (total, item) => total + item.Rate * item.Quantity,
    0
  );
  const HandelClear = () => {
    reset();
    setChoosedItems([]);
    setQty("");
    setselectitem("");
    setTablelist([]);
    Dispatch(SaleInsertFail());
    Dispatch(PrintPurchaseFail())
  };

  console.log("SaleData",SaleData);
  useEffect(() => {
    if (SaleData.insertreturn?.Id > 0 &&SaleData.Type=='1') {
      toast.success("Issue success");

    }
  }, [SaleData]);

  const handleitem = (item) => {
    const itemsWithQuantity = { ...item.Item };
    setChoosedItems(itemsWithQuantity);
    setselectitem(item);
    setQty(1);
  };

  const handlecalculation = () => {
    if (Object.keys(ChoosedItems).length > 0) {
      const newItem = { ...ChoosedItems, Quantity: parseFloat(Qty),Discount:parseInt(DiscountAmount)||0 };
 
      setTablelist([...tablelist, newItem]);
      setChoosedItems([]);
      setQty("");
      setselectitem("");
    } else {
      alert("Please select Items Before Clicking the Add Button");
    }
  };

  const handlepending = (adv, dis) => {
    setpending(TotalAmount - dis - adv || TotalAmount);
  };

  const HandelSave = (data) => {
    // e.preventDefault()
    console.log("clicked");
    
    if (fetch) {
      setfetch(false);
    }
    if (data.party && data.paymentType ) {
      if(tablelist.length){
      let partyid =
        PartyData && PartyData.find((item) => item.Id == data.party);
      let partyname = partyid ? partyid.Name : "";
   
      Dispatch(
        SaleInsert({
          Id: 0,
          BillAmount: TotalAmount || 0,
          BarcodeNumber: "",
          BillDate: "2024-03-14T08:54:29.800Z",
          AdvanceAmount: parseInt(AdvanceAmount) || 0,
          PartyId: data.party,
          PartyName: partyname,
          Payment: data.paymentType,
          Discount: parseInt(DiscountAmount)|| 0,
          Tax: 0,
          Remarks: data.remarks,
          UserId: UserId,
          TId: 0,
          Amount: TotalAmount || 0,
          OrderDate: "2024-03-14T08:54:29.800Z",
          DeliveryDate: data.DeliveryDate,
          BillType: 0,
          BillStatus: 0,
          TransactionItems: tablelist,
          AccountingYearId: 4,
          Type: 1,
          InvoiceNo: "",
          InvoiceDate: "",
          Uploads: "",
          TempleId: TempleID,
        })
      );
      }else{
        alert("Click Add Button")
      }
    } else {
      alert("Please select the party And payment Type ");
    }
  };

  const [showfetchmodal, setshowfetchmodal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const handelfetchmodal = () => {
    // setfetch(!fetch)
    setshowfetchmodal(!showfetchmodal);
  };


  const componentRef = React.useRef();

  const handleEdit = (index) => {
    setEditingIndex(index);
  };
  console.log("SaleData",SaleData);
  return (
    <div> 
      <PrintIssue ref={componentRef} transactionId={SaleData?.insertreturn?.Id} />
      
      {showfetchmodal && (
        <ItemFetchModal
          showfetchmodal={showfetchmodal}
          setshowfetchmodal={setshowfetchmodal}
          onFetchSuccess={handleFetchSuccess}
          // ChoosedItems={ChoosedItems}
          // setChoosedItems={setChoosedItems}
        />
      )}
      <div className='class="max-w-sm mt-5 overflow-y-auto rounded-lg border border-gray-200 bg-white p-6 shadow dark:border-gray-700 dark:bg-gray-800 md:h-[600px]'>
        <form onSubmit={handleSubmit(HandelSave)}>
          <div className="container mx-auto">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => {
                  handelfetchmodal();
                }}
                class="mb-2 rounded-lg bg-blue-700 px-5 py-2 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Fetch Item   
              </button>
            </div>

            <div className="mr-2 md:w-full">
              <label
                for="message"
                class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Party <span className="text-red-500">*</span>
              </label>
              <select
                {...register("party")}
                className="block w-full rounded-lg border border-green-500 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                id="party"
              >
                <option value="">Select Party</option>
                {Array.isArray(PartyData) &&
                  PartyData.length > 0 &&
                  PartyData.map((party) => (
                    <option key={party.Id} value={party.Id}>
                      {party.Name}
                    </option>
                  ))}
              </select>
            </div>

            <div className=" md:flex  ">
              <div className=" mr-2 md:w-2/6">
                <label
                  for="message"
                  class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Items <span className="text-red-500">*</span>
                </label>
                <Select
                  options={
                    ItemData && ItemData.length > 0
                      ? ItemData.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Name,
                            Item: item,
                          };
                        })
                      : []
                  }
                  placeholder="Select Item"
                  value={selectitem}
                  onChange={handleitem}
                  isSearchable={true}
                />
              </div>

              <div className="mr-2 md:w-1/6 ">
                <label
                  for="message"
                  class="  mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Quantity <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  value={Qty}
                  onChange={(e) => {
                    setQty(e.target.value);
                  }}
                />
              </div>

              <div className="mr-2 md:w-1/6">
                <label
                  for="message"
                  class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Rate <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  value={ChoosedItems.Rate || ""}
                  onChange={(e) =>
                    setChoosedItems({ ...ChoosedItems, Rate: e.target.value })
                  }
                />
              </div>
              <div className=" md:flex md:w-2/6">
                <div className="mr-2 md:w-2/3">
                  <label
                    for="message"
                    class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Total Amount
                  </label>
                  <input
                    type="text"
                    className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    value={Qty * ChoosedItems.Rate || ""}
                    readOnly
                  />
                </div>
                <div className="mr-2 md:w-1/3">
                  <button
                    type="button"
                    class="mt-7 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => {
                      handlecalculation();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            {tablelist.length > 0 ? (
              <div className="mb-5 mt-7 overflow-x-auto border border-green-300">
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        className="text-large font-xx-large text-black-700 px-8 py-3 text-left uppercase tracking-wider"
                        style={{ width: "40%" }}
                      >
                        <b>Item Name</b>
                      </th>
                      <th
                        className="text-large font-xx-large text-black-700 px-4 py-3 text-left uppercase tracking-wider"
                        style={{ width: "15%" }}
                      >
                        Fetched Quantity
                      </th>
                      <th
                        className="text-large font-xx-large text-black-700 px-4 py-3 text-left uppercase tracking-wider"
                        style={{ width: "15%" }}
                      >
                       Actual Issued
                      </th>
                      {fetch ? (
                        ""
                      ) : (
                        <th
                          className="text-large font-xx-large text-black-700 px-4 py-3 text-left uppercase tracking-wider"
                          style={{ width: "15%" }}
                        >
                          Rate
                        </th>
                      )}
                      {fetch ? (
                        ""
                      ) : (
                        <th
                          className="text-large font-xx-large text-black-700 px-4 py-3 text-left uppercase tracking-wider"
                          style={{ width: "15%" }}
                        >
                          Amount
                        </th>
                      )}
                      <th
                        className="text-large font-xx-large text-black-700 px-6 py-3 text-left uppercase tracking-wider"
                        style={{ width: "15%" }}
                        colSpan="2"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
        {tablelist.map((item, index) => {
          // Initialize fetchedQuantity if not present
          if (!item.fetchedQuantity) {
            item.fetchedQuantity = item.Quantity;
          }

          return (
            <tr key={index}>
              <td className="whitespace-nowrap px-8 py-4">
                {item.Name} [{item.Unit ? item.Unit : ""}]
              </td>
              <td className="whitespace-nowrap px-8 py-4">
                {/* Display fetchedQuantity */}
                {!isNaN(parseFloat(item.fetchedQuantity)) ? parseFloat(item.fetchedQuantity).toFixed(3) : ""}
              </td>
              <td className="whitespace-nowrap px-8 py-4">
                {editingIndex === index ? (
                  <input
                    type="text"
                    value={parseFloat(item.Quantity)?.toFixed(3)}
                    className="block w-full rounded-lg border border-green-500 bg-gray-50 p-2.5 text-sm text-gray-900"
                    onChange={(e) => {
                      const newTableList = [...tablelist];
                      newTableList[index].Quantity = e.target.value;
                      setTablelist(newTableList);
                    }}
                    onKeyDown={(e) => {
                      // Prevent default Enter key behavior
                      if (e.keyCode === 13) {
                        e.preventDefault();  // Prevent form submission if this input is part of a form
                        e.stopPropagation();  // Stop the event from bubbling up to parent handlers
              
                        // If you want to perform additional logic on Enter key press:
                        const newTableList = [...tablelist];
                        newTableList[index].Quantity = e.target.value;
                        setTablelist(newTableList);
                      }
                    }}
                  />
                ) : (
                  parseFloat(item.Quantity)?.toFixed(3)
                )}
              </td>

              {!fetch && (
                <>
                  <td className="whitespace-nowrap px-4 py-4">
                    {item?.Rate}
                  </td>
                  <td className="whitespace-nowrap px-4 py-4">
                    {item.Rate && item.Quantity ? parseFloat(item.Rate) * parseFloat(item.Quantity) : 0}
                  </td>
                </>
              )}
              <td className="whitespace-nowrap px-6 py-4">
                <button
                  type="button"
                  className="text-red-600 hover:text-red-900"
                  onClick={() => {
                    const newItems = [...tablelist];
                    newItems.splice(index, 1);
                    setTablelist(newItems);
                  }}
                >
                  <MdDeleteForever className="mt-1" style={{ fontSize: "1.5rem" }} />
                </button>
                {fetch && (
                  <button type="button" className="text-green-600" onClick={() => handleEdit(index)}>
                    <MdEditNote className="mt-1" style={{ fontSize: "1.5rem" }} />
                  </button>
                )}
              </td>
            </tr>
          );
        })}

        {/* Additional rows for totals */}
        {!fetch && (
          <>
            <tr>
              <td colSpan={4} className="mt-1">
                <div className="mt-5 p-2">
                  <b>Total Amount:</b>{" "}
                </div>
              </td>
              <td>
                <div className="mt-5 flex justify-center p-2">
                  <b>{TotalAmount}</b>{" "}
                </div>
              </td>
            </tr>
            {/* Additional input fields for Discount, Grand Total, etc. */}
            {/* ... */}
          </>
        )}
      </tbody>
                </table>
              </div>
            ) : null}

            <div className="flex ">
              <div className="mr-2 w-1/2">
                <label
                  for="message"
                  class=" mt-5 mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Delivery Date
                </label>
                <input
                  type="date"
                  className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  id=""
                  {...register("DeliveryDate")}
                />
              </div>
              <div className="w-1/2">
                <label
                  for="message"
                  class=" mt-5 mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Payment Type <span className="text-red-500">*</span>
                </label>

                <select
                  {...register("paymentType")}
                  className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  id=""
                >
                  <option value="">Select payment method</option>
                  <option value="0">Cash</option>
                  <option value="1">Card</option>
                  <option value="2">Credit</option>
                </select>
              </div>
            </div>
            <div>
              <label
                for="message"
                class="mt-6 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Remarks
              </label>
              <textarea
                id="message "
                {...register("remarks")}
                rows="2"
                class="mt-4 mb-2 block w-full rounded-lg border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                placeholder="Write your Remarks..."
              ></textarea>
            </div>
            <div className="flex  justify-end ">
              {/* <div>
                <button
                  type="button"
                  class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Print
                </button>
              </div> */}
              <div>
                <button
                  type="submit"
                  class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={HandelClear}
                  class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Clear
                </button>
              </div>
              <ReactToPrint
                trigger={() => (
                  <button
                    class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="button"
                    disabled={!SaleData?.insertreturn?.Id}
                  >
                    Print
                  </button> 
                )}
                content={() => componentRef.current}
                onAfterPrint={() => {
                  reset();
                  setChoosedItems([]);
                  setQty("");
                  setselectitem("");
                  setTablelist([]);
                  Dispatch(SaleInsertFail());
                  Dispatch(PrintPurchaseFail())
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Sales;
