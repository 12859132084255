import React from "react";

// Admin Imports
import Dashboad from "views/admin/Dashboad";
import Item from "./views/admin/Item";
import FastBilling from "views/admin/FastBilling";
import Party from "views/admin/Party";
import PartyType from "./views/admin/Master/PartyType";
import Purchase from "views/admin/Purchase";
import Sale from "views/admin/Sale"
import PurchaseOrder from "views/admin/PurchaseOrder"
import PurchaseReturn from "views/admin/PurchaseReturn";
import SaleOrder from "./views/admin/Ordersale"
import SaleReturn from "./views/admin/Returnsale"
import Dispatch from "./views/admin/Dispatch"

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { BiPurchaseTag } from "react-icons/bi";
import { GoIssueClosed } from "react-icons/go";
import { RiFileDamageLine } from "react-icons/ri";
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdList,
  MdReport
} from "react-icons/md";
import PriceGroup from "views/admin/Master/PriceGroup";
import VazhipaduMapping from "views/admin/Vazhipadu mapping/VazhipaduMapping";
import StockReport from "views/admin/Report/StockReportpage";
import VazhipaduWiseItemReport from "views/admin/Report/VazhipaduWiseItemReport";
import TransactionReport from "views/admin/Report/TransactionReport";
import ApprovalPage from "views/admin/Report/ApprovalPage";
import Approval from "views/admin/Report/Approval";

const routes = [
  {
    name: " Dashboard",
    layout: "/admin",
    path: "Dashboad",
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboad />,
  },
  
  {
    name: "Party Type",
    layout: "/admin",
    path: "PartyType",
    icon: <MdPerson className="h-6 w-6" />,
    component: <PartyType />,
  },
  {
    name: "Price Group",
    layout: "/admin",
    path: "PriceGroup",
    icon: <MdPerson className="h-6 w-6" />,
    component: <PriceGroup />,
  },
  {
    name: "Items",
    layout: "/admin",
    path: "Item",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <Item />,
    secondary: true,
  },
  {
    name: "Party",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "Party",
    component: <Party />,
  },
  
  {
    name: "Vazhipadu Mapping",
    layout: "/admin",
    path: "vazhipadumapping",
    icon: <MdList className="h-6 w-6" />,
    component: <VazhipaduMapping />,
  },
 
  // {
  //   name: "Fast Billing",
  //   layout: "/admin",
  //   path: "Fastbilling",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <FastBilling />,
  // },
  {
    name: "Stock Report",
    layout: "/admin",
    path: "StockReport",
    icon: <MdReport className="h-6 w-6" />,
    component: <StockReport />,
  },
  {
    name: "Recipe Report ",
    layout: "/admin",
    path: "VazhipaduWiseItemReport",
    icon: <MdReport className="h-6 w-6" />,
    component: <VazhipaduWiseItemReport />,
  },
  {
    name: "Approval  ",
    layout: "/admin",
    path: "ApprovalPage",
    icon: <MdReport className="h-6 w-6" />,
    component: <ApprovalPage />,
  },
  {
    name: "Transaction Report ",
    layout: "/admin",
    path: "TransactionReport",
    icon: <MdReport className="h-6 w-6" />,
    component: <TransactionReport />,
  },
  {
    name: "Purchase",
    layout: "/admin",
    path: "Purchasepage",
    icon: <BiPurchaseTag className="h-6 w-6" />,
    component: <Purchase />,
  },
  {
    name: "Purchase Order",
    layout: "/admin",
    path: "OrderPurchase",
    icon: <BiPurchaseTag className="h-6 w-6" />,
    component: <PurchaseOrder />,
  },

  {
    name: "Purchase Return",
    layout: "/admin",
    path: "ReturnPurchase",
    icon: <BiPurchaseTag className="h-6 w-6" />,
    component: <PurchaseReturn/>,
  },
  {
    name: "Issue",
    layout: "/admin",
    path: "Sale",
    icon: <GoIssueClosed className="h-6 w-6" />,
    component: <Sale/>,
  },
 

  // {
  //   name: "Issue Order",
  //   layout: "/admin",
  //   path: "Ordersale",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <SaleOrder/>,
  // },

  {
    name: "Issue Return",
    layout: "/admin",
    path: "Returnsale",
    icon: <GoIssueClosed className="h-6 w-6" />,
    component: <SaleReturn/>,
  },

  {
    name: "Damage",
    layout: "/admin",
    path: "Dispatch",
    icon: <RiFileDamageLine className="h-6 w-6" />,
    component: <Dispatch/>,
  },

];
export default routes;


