// import Select from "react-select";
// import React from 'react';
// import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Listitem } from "../../../Redux/Items/action";
import axios from "axios";
import { apiurl } from "configs/config";
import toast from "react-hot-toast";
// import { date } from "zod";
import Select from "react-select";
import React, { useState } from 'react';

function ItemFetchModal(props) {
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const [selectedSession, setSelectedSession] = useState(null);

  const [ItemData, setItemData] = useState([
    { label: "Morning", value: "1" },
    { label: "Evening", value: "2" }
  ]);    
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); 

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); 
  }; 
  useEffect(() => {
    // Fetch session options from backend
    axios
      .get(`${apiurl}/api/Session/GetSessionMapperData?TempleId=${TempleID}`)
      .then((response) => {
        
        if (response.data && response.data.Result) {
          setItemData(response.data.Result.map((session) => ({
            value: session.Id,
            label: session.Name,
          })));
        }
      })
      .catch((error) => {
      
        toast.error('Error fetching session details');
      });
  }, [TempleID]);
  const handleFetch =()=>{
   
   
    const formattedDate = new Date(selectedDate).toISOString(); 
    axios
    .get(`${apiurl}/api/Report/GetItemWiseListForStoreAfterDayClose?TempleId=${TempleID}&Date=${formattedDate}&Session=${selectedSession.value}`)
    .then((response) => {
     
      if (response) {
       
        
        if(response?.data?.Result[0]?.Data?.length>0){
          toast.success("Item mapped successfully");
        props.onFetchSuccess(response.data.Result[0].Data);
        }else{
          toast.error("No Data available for selected date");
        }

      }
      props.setshowfetchmodal(!props.showfetchmodal);
    })
    .catch((error) => {
    console.log(error,"asdf");
    
      toast.error("Error ", error);
    });
  }
  
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div id="default-modal" tabIndex="-1" aria-hidden="true" className="relative p-4 w-full max-w-lg">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Item Fetch</h3>
            <button onClick={()=>{props.setshowfetchmodal(!props.showfetchmodal)}} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5">
          <label
        htmlFor="date"
        className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
      >
        Date
      </label>
      <input 
        type="date" 
        id="date" 
        name="date" 
        value={selectedDate}
        onChange={handleDateChange}
        className="block w-full rounded-lg border border-green-500 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
      />
      </div>
      <div className="px-4 md:px-5 ">
        <label htmlFor="message" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
          Session
        </label>
        <Select
          options={ItemData}
          placeholder="Select Session"
          value={selectedSession}
          onChange={(selectedOption) => setSelectedSession(selectedOption)}
          isSearchable={true}
        />
      </div>


          <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button data-modal-hide="default-modal"
             type="button" 
             className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5"
             onClick={() => {
              // 
              handleFetch();
            }}
              >
               Fetch
                </button>
            {/* <button data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Decline</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemFetchModal;
