import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { GetAllParty, GetParty } from "../../../Redux/Party/action";
import { Listitem } from "../../../Redux/Items/action";
import toast from "react-hot-toast";
import { FaEdit } from "react-icons/fa";
import { useForm, useWatch } from "react-hook-form";
import { MdDeleteForever } from "react-icons/md";
import Select from "react-select";
import PurchaseItemModal from "../Purchase/PurchaseItemModal";
import { SaleInsert, SaleInsertFail } from "../../../Redux/Sale/action";
import { ResetIcon } from "@radix-ui/react-icons";

function Dispatch() { 
  const [ChoosedItems, setChoosedItems] = useState([]);
  const [ShowModal, setShowModal] = useState(false);
  const [Qty, setQty] = useState("");
  const [selectitem, setselectitem] = useState("");
  const [tablelist, setTablelist] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
  } = useForm({
    defaultValues: {
      DeliveryDate: format(new Date(), "yyyy-MM-dd"),
      AdvanceAmount: ''
    },
    mode: "all",
  });
  const AdvanceAmount = useWatch({
    control,
    name: "AdvanceAmount",
  })

  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const UserId = JSON.parse(localStorage.getItem("Sopanam_UserId"));
  const PartyData = useSelector((state) => state.Party.GetAllPartyData);
  const ItemData = useSelector((state) => state.Items.ListItemData);
  const SaleData = useSelector((state) => state.Sale.SaleData);


  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GetAllParty(TempleID));
    Dispatch(
      Listitem({
        TempleId: TempleID,
      })
    );
  }, []);
  const TotalAmount = tablelist?.reduce(
    (total, item) => total + item.Rate * item.Quantity,
    0
  );

  useEffect(() => {
    console.log("SaleData",SaleData);
    
    if (SaleData?.insertreturn?.Id > 0) {
      toast.success("The damaged item was saved successfully");
      setTablelist([]);
      reset();
      Dispatch(SaleInsertFail())
    }
  }, [SaleData]);

  const handleitem = (item) => {
    const itemsWithQuantity = { ...item.Item };
    setChoosedItems(itemsWithQuantity);
    setselectitem(item)
    setQty(1);
  };

  const handlecalculation =()=>{
    if(Object.keys(ChoosedItems).length>0){
    const newItem = { ...ChoosedItems, Quantity: parseInt(Qty) };
    setTablelist([...tablelist, newItem]);
    setChoosedItems([])
    setQty('')
    setselectitem('')
  }
  else{
    alert("Please select Items Before Clicking the Add Button")
  } 
  
  }

  const HandelSave = (data) => {
    if(tablelist.length){
    let partyid = PartyData && PartyData.find((item) => item.Id == data.party);
    let partyname = partyid ? partyid.Name : "";
    Dispatch(
      SaleInsert({
      Id: 0,
      BillAmount: TotalAmount,
      BarcodeNumber: "string",
      BillDate: "2024-03-13T11:30:28.079Z",
      AdvanceAmount: data.AdvanceAmount || 0,
      PartyId: data.party,
      PartyName: partyname,
      Payment: data.paymentType,
      Discount: 0,
      Tax: 0,
      Remarks: data.remarks,
      UserId: UserId,
      TId: 0,
      Amount: TotalAmount,
      OrderDate: "2024-03-13T11:30:28.079Z",
      DeliveryDate: data.DeliveryDate,
      BillType: 0,
      BillStatus: 0,
      TransactionItems: tablelist,
      AccountingYearId: 4,
      Type:5 ,
      InvoiceNo: "string",
      InvoiceDate: data.invoiceDate ||"2024-03-13T11:30:28.079Z",
      Uploads: "string",
      TempleId: TempleID
    
  }
))
    }else{
      alert("Click Add Button")
    }
   
  };

  const HandelShowModal = () => {
    if (getValues("party")) {
      setShowModal(true);
    } else {
      toast.error("Please select the party");
    }
  };

  return (
    <div>
     
      <div className='class="max-w-sm dark:border-gray-700" mt-5 h-[600px] overflow-y-auto rounded-lg border border-gray-200 bg-white p-6 shadow dark:bg-gray-800'>
        <form onSubmit={handleSubmit(HandelSave)}>
          <div className="container mx-auto">
           
            
{/* 
              <div className="mr-2 md:w-full">
                <label
                  for="message"
                  class=" mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Party
                </label>
                <select
                  {...register("party")}
                  className="block w-full rounded-lg border border-green-500 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  id="party"
                >
                  <option value="">Select Party</option>
                  {Array.isArray(PartyData) &&
                    PartyData.length > 0 &&
                    PartyData.map((party) => (
                      <option key={party.Id} value={party.Id}>
                        {party.Name}
                      </option>
                    ))}
                </select>
              </div> */}
       <div className="md:flex md:space-x-4">
  <div className="md:w-1/4 mb-4">
    <label
      htmlFor="message"
      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
    >
      Items
    </label>
    <Select
      options={
        ItemData && ItemData.length > 0
          ? ItemData.map((item) => ({
              value: item.Id,
              label: item.Name,
              Item: item,
            }))
          : []
      }
      placeholder="Select Item"
      value={selectitem}
      onChange={handleitem}
      isSearchable={true}
    />
  </div>

  <div className="md:w-1/6 mb-4">
    <label
      htmlFor="message"
      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
    >
      Quantity
    </label>
    <input
      type="number"
      className="block w-full rounded-lg border border-green-500 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
      value={Qty}
      onChange={(e) => setQty(e.target.value)}
    />
  </div>

  <div className="md:w-1/6 mb-4">
    <label
      htmlFor="message"
      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
    >
      Rate
    </label>
    <input
      type="text"
      className="block w-full rounded-lg border border-green-500 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
      value={ChoosedItems.Rate || ''}
      readOnly
    />
  </div>

  <div className="md:w-1/6 mb-4">
    <label
      htmlFor="message"
      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
    >
      Total Amount
    </label>
    <input
      type="text"
      className="block w-full rounded-lg border border-green-500 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
      value={(Qty * ChoosedItems.Rate) || ""}
      readOnly
    />
  </div>

  <div className="md:w-1/4 mb-4">
  Attach File
    <label
      htmlFor="file-upload"
      className="block w-full cursor-pointer rounded-lg border border-green-500 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
    >
      {selectedFile ? `Selected: ${selectedFile.name}` : "Upload Attachment"}
    </label>
    <input
      id="file-upload"
      type="file"
      className="hidden"
      onChange={handleFileChange}
    />
    {selectedFile && (
      <p className="mt-2 text-sm text-green-600">
        {selectedFile.name} has been selected.
      </p>
    )}
  </div>

  <div className="md:w-1/6 flex items-end mb-4">
    <button
      type="button"
      className="rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      onClick={() => handlecalculation()}
    >
      Add
    </button>
  </div>
</div>


            {tablelist.length > 0 ? (
  <div className="overflow-x-auto mb-5 mt-7 border border-green-300">
    <table className="table-auto min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-8 py-3 text-left text-large font-xx-large text-black-700 uppercase tracking-wider" style={{ width: '40%' }}>
            <b>Item Name</b>
          </th>
          <th className="px-4 py-3 text-left text-large font-xx-large text-black-700 uppercase tracking-wider" style={{ width: '15%' }}>
            Quantity
          </th>
          <th className="px-4 py-3 text-left text-large font-xx-large text-black-700 uppercase tracking-wider" style={{ width: '15%' }}>
            Rate
          </th>
          <th className="px-4 py-3 text-left text-large font-xx-large text-black-700 uppercase tracking-wider" style={{ width: '15%' }}>
            Amount
          </th>
          <th className="px-6 py-3 text-left text-large font-xx-large text-black-700 uppercase tracking-wider" style={{ width: '15%' }} colSpan="2">
            Action
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {tablelist.map((item, index) => (
          <tr key={index}>
            <td className="px-8 py-4 whitespace-nowrap">
              {item.Name}
            </td>
            <td className="px-4 py-4 whitespace-nowrap">
              {item.Quantity}
            </td>
            <td className="px-4 py-4 whitespace-nowrap">
              {item.Rate}
            </td>
            <td className="px-4 py-4 whitespace-nowrap">
              {item.Rate && item.Quantity ? parseFloat(item.Rate) * parseFloat(item.Quantity) : 0}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <button
                className="text-red-600 hover:text-red-900"
                onClick={() => {
                  const newItems = [...tablelist];
                  newItems.splice(index, 1);
                  setTablelist(newItems);
                }}
              >
                <MdDeleteForever className="mt-1" style={{ fontSize: "1.5rem" }} />
              </button>
            </td>
          </tr>
        ))}
         {/* <tr>
                        <td colSpan={4} className="mt-1 "><div className=" mt-5 p-2"><b>Total Amount:</b> </div></td>
                        <td> <div className="justify-center flex  mt-5 p-2"> <b>{TotalAmount }/- </b> </div> </td>

                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 "><div className="  mt-5 p-2"><b>Discount Amount:</b> </div></td>
                        <td> <div className="justify-center flex  mt-5 p-2"> <b>{DiscountAmount}/- </b> </div> </td>

                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 "><div className="  mt-5 p-2"><b>Grand Total:</b> </div></td>
                        <td> <div className="justify-center flex  mt-5 p-2"> <b>{(TotalAmount - DiscountAmount||'')}/- </b> </div> </td>

                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 "><div className="  mt-5 p-2"><b>Advance Amount:</b> </div></td>
                        <td> <div className="justify-center flex  mt-5 p-2"> <b>{AdvanceAmount}/- </b> </div> </td>

                      </tr>
                      <tr>
                        <td colSpan={4} className="mt-1 "><div className=" mt-5 p-2"><b>Pending Amount:</b> </div></td>
                        <td> <div className="justify-center flex  mt-5 p-2"> <b>{((TotalAmount - DiscountAmount) - AdvanceAmount||TotalAmount)}/- </b> </div> </td>

                      </tr> */}
      </tbody>
    </table>
  </div>
) : null}

            <div className="flex ">
              {/* <div className="mr-2 w-1/2">
                <label
                  for="message"
                  class=" mt-5 mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Total Amount
                </label>

                <input
                  type="text"
                  disabled
                  value={TotalAmount||0}
                  className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  id=""
                />
              </div> */}
              {/* <div className="w-full">
                <label
                  for="message"
                  class=" mt-2 mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Payment Type
                </label>

                <select
                  {...register("paymentType")}
                  className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                  id=""
                >
                  <option value="">Select payment method</option>
                  <option value="0">Cash</option>
                  <option value="1">Card</option>
                  <option value="2">Credit</option>
                </select>
              </div> */}
             </div>
           {/* <div className="mt-3 flex w-full">
              <div className="mr-2 w-1/2">
                <div className="">
                  <label
                    for="message"
                    class="  mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Advance
                  </label>
                  <input
                    type="number"
                    className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    id=""
                    {...register("AdvanceAmount")}
                  />
                </div>
              </div>
              <div className="mr-2 w-1/2">
                <div>
                  <label
                    for="message"
                    class="  mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Delevery Date
                  </label>
                  <input
                    type="date"
                    className=" block w-full rounded-lg   border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                    id=""
                    {...register("DeliveryDate")}
                  />
                </div>
              </div>
            </div> */}
            <div>
              <label
                for="message"
                class="mt-6 block text-sm font-medium text-gray-900 dark:text-white"
              >
                Remarks
              </label>
              <textarea
                id="message "
                {...register("remarks")}
                rows="3"
                class="mt-4 mb-2 block flex-1 w-full rounded-lg border border border-green-500 border-gray-700 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                placeholder="Write your Remarks..."
              ></textarea>
            </div>
           
            <div className="flex  justify-end ">
              {/* <div>
                <button
                  type="button"
                  class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Print
                </button>
              </div> */}
              <div>
                <button
                  type="submit"
                  class="mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white me-2 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Dispatch;
